import { Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { Button, Link, Picture } from '@troon/ui';
import { IconStar, IconStarFilled } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useAction } from '@solidjs/router';
import { gql, mutationAction, useMutation } from '../../graphql';
import { useUser } from '../../providers/user';
import type { FacilityCardFragment } from '../../graphql';

type Props = {
	facility: FacilityCardFragment;
	level?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	loading?: 'eager' | 'lazy';
	linkToTeeTimes?: boolean;
	disableFavorites?: boolean;
};

export function FacilityCard(props: Props) {
	const user = useUser();
	const favorite = useAction(useMutation(handleFavorite));

	return (
		<article class="group relative">
			{props.facility.metadata?.hero?.url ? (
				<Picture
					src={props.facility.metadata?.hero?.url ?? ''}
					sizes={[
						[320, 180],
						[400, 225],
						[500, 281],
					]}
					alt={`Photo from the golf course at ${props.facility.name}`}
					width={500}
					height={281}
					class="mb-2 aspect-video w-full rounded-lg bg-neutral-500 object-cover"
					loading={props.loading}
				/>
			) : (
				<div class="mb-2 aspect-video w-full rounded-lg bg-neutral-500" />
			)}
			<Dynamic component={props.level ?? 'h2'} class="text-base font-semibold">
				<Link
					href={`/course/${props.facility.slug}${props.linkToTeeTimes ? '/reserve-tee-time/' : ''}`}
					class="transition-colors duration-200 after:absolute after:inset-0 after:z-0 group-hover:text-brand-500"
				>
					{props.facility.name}
				</Link>
			</Dynamic>
			<Show when={props.facility.metadata?.address?.city || props.facility.metadata?.address?.state}>
				<p class="text-sm">
					<Show when={props.facility.metadata?.address?.city}>{props.facility.metadata?.address?.city}, </Show>
					{props.facility.metadata?.address?.state}
				</p>
			</Show>
			<Show when={!props.disableFavorites && user()}>
				<Button
					appearance="transparent-current"
					class="absolute right-2 top-2 text-black"
					onClick={() => {
						const data = new FormData();
						data.set('facilityId', props.facility.id);
						data.set('isFavorite', props.facility.isFavorite ? 'false' : 'true');
						favorite(data);
					}}
				>
					<IconStarFilled
						class={twJoin('absolute z-10 text-xl', props.facility.isFavorite ? 'text-brand' : 'text-black/30')}
					/>
					<IconStar class="relative z-20 text-xl text-white" />
					<span class="sr-only">Favorite</span>
				</Button>
			</Show>
		</article>
	);
}

export const courseCardFragment = gql(`
fragment FacilityCard on Facility {
	id
	slug
	name
	isFavorite
	metadata {
		address {
			city
			state
		}
		hero {
			url
		}
	}
}
`);

const toggleFavorite = gql(`
mutation toggleFavoriteFacility($facilityId: String!, $isFavorite: Boolean!) {
	toggleFavoriteFacility(facilityId: $facilityId, isFavorite: $isFavorite) {
		name
		isFavorite
	}
}
`);

const handleFavorite = mutationAction(toggleFavorite, {
	transform: (data) => ({
		facilityId: data.get('facilityId') as string,
		isFavorite: data.get('isFavorite') === 'true',
	}),
	toast: (data) =>
		data?.toggleFavoriteFacility.isFavorite
			? `Added ${data?.toggleFavoriteFacility.name} as a favorite`
			: `Removed ${data?.toggleFavoriteFacility.name} as a favorite.`,
	revalidates: ['facility', 'home', 'facilities', 'searchFacilitiesList'],
});
